import { permissionActionName, permissionSubjectName, PermissionSuffix } from '@/service/api/core/enums/permission';
import { PermissionEnum } from '@vini-wine/core-enums';
export default [
    {
        path: '/administration/organisations/claims',
        name: 'administration-user-organisation-claim-requests',
        component: () => import('@/views/administration/user-organisation-claim-requests/Index.vue'),
        meta: {
            resource: permissionSubjectName(PermissionEnum.USER_ORGANISATION_CLAIM_REQUEST__VIEW, PermissionSuffix.USER),
            action: permissionActionName(PermissionEnum.USER_ORGANISATION_CLAIM_REQUEST__VIEW),
            pageTitle: 'pageTitles.userOrganisationClaimRequests.list',
            breadcrumb: [
                {
                    text: 'breadcrumbs.userOrganisationClaimRequests.userOrganisationClaimRequestsList.claims',
                },
                {
                    text: 'breadcrumbs.userOrganisationClaimRequests.userOrganisationClaimRequestsList.list',
                    active: true,
                },
            ],
        },
    },
];
