import { handleOrganisationRoutes } from '@/helpers/router';
import { DEFAULT_ABILITIES } from '@/helpers/auth';
export default [
    {
        path: '/organisation/:orgUuid/wine-searcher',
        name: 'organisation-wine-searcher',
        component: () => import('@/views/wine-searcher/WineSearcher.vue'),
        meta: {
            resource: DEFAULT_ABILITIES.USER_LOGGED_IN.subject,
            action: DEFAULT_ABILITIES.USER_LOGGED_IN.action,
            pageTitle: 'pageTitles.wineSearcher.wineSearcher',
            breadcrumb: [
                {
                    text: 'breadcrumbs.wineSearcher.wineSearcher.more',
                },
                {
                    text: 'breadcrumbs.wineSearcher.wineSearcher.wineSearcher',
                    active: true,
                },
            ],
        },
        beforeEnter: (to, from, next) => {
            handleOrganisationRoutes(to);
            next();
        },
    },
];
