import { permissionActionName, permissionSubjectName, PermissionSuffix } from '@/service/api/core/enums/permission';
import { PermissionEnum } from '@vini-wine/core-enums';
export default [
    {
        path: '/administration/uploads',
        name: 'administration-uploads-list',
        component: () => import('@/views/administration/uploads/Index.vue'),
        meta: {
            resource: permissionSubjectName(PermissionEnum.UPLOAD__VIEW, PermissionSuffix.USER),
            action: permissionActionName(PermissionEnum.UPLOAD__VIEW),
            pageTitle: 'pageTitles.administrationUploads.list',
            breadcrumb: [
                {
                    text: 'breadcrumbs.administrationUploads.list.uploads',
                },
                {
                    text: 'breadcrumbs.administrationUploads.list.list',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/administration/uploads/new',
        name: 'administration-uploads-create',
        component: () => import('@/views/administration/uploads/Create.vue'),
        meta: {
            resource: permissionSubjectName(PermissionEnum.UPLOAD__CREATE, PermissionSuffix.USER),
            action: permissionActionName(PermissionEnum.UPLOAD__CREATE),
            pageTitle: 'pageTitles.administrationUploads.match',
            goBackRoute: 'administration-uploads-list',
            breadcrumb: [
                {
                    text: 'breadcrumbs.administrationUploads.match.uploads',
                },
                {
                    text: 'breadcrumbs.administrationUploads.match.match',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/administration/uploads/b2b-market-price',
        name: 'administration-uploads-b2b-market-price',
        component: () => import('@/views/administration/uploads/IndexB2bMarketPriceUploads.vue'),
        meta: {
            resource: permissionSubjectName(PermissionEnum.UPLOAD_B2B_MARKET_PRICES_VIEW, PermissionSuffix.USER),
            action: permissionActionName(PermissionEnum.UPLOAD_B2B_MARKET_PRICES_VIEW),
            pageTitle: 'pageTitles.administrationUploads.b2bMarketPrice',
            breadcrumb: [
                {
                    text: 'breadcrumbs.administrationUploads.b2bMarketPrice.uploads',
                },
                {
                    text: 'breadcrumbs.administrationUploads.b2bMarketPrice.b2bMarketPrice',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/administration/uploads/b2b-market-price/new',
        name: 'administration-uploads-new-b2b-market-price',
        component: () => import('@/views/administration/uploads/UploadB2bMarketPrice.vue'),
        meta: {
            resource: permissionSubjectName(PermissionEnum.UPLOAD_B2B_MARKET_PRICES_CREATE, PermissionSuffix.USER),
            action: permissionActionName(PermissionEnum.UPLOAD_B2B_MARKET_PRICES_CREATE),
            pageTitle: 'pageTitles.administrationUploads.b2bMarketPrice',
            goBackRoute: 'administration-uploads-b2b-market-price',
            breadcrumb: [
                {
                    text: 'breadcrumbs.administrationUploads.b2bMarketPrice.uploads',
                },
                {
                    text: 'breadcrumbs.administrationUploads.b2bMarketPrice.b2bMarketPrice',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/administration/uploads/critic-scores',
        name: 'administration-uploads-critic-scores',
        component: () => import('@/views/administration/uploads/IndexCriticScoresUploads.vue'),
        meta: {
            resource: permissionSubjectName(PermissionEnum.VINTAGE_SCORE_UPLOAD_VIEW, PermissionSuffix.USER),
            action: permissionActionName(PermissionEnum.VINTAGE_SCORE_UPLOAD_VIEW),
            pageTitle: 'pageTitles.administrationUploads.criticScores',
            breadcrumb: [
                {
                    text: 'breadcrumbs.administrationUploads.criticScores.uploads',
                },
                {
                    text: 'breadcrumbs.administrationUploads.criticScores.criticScores',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/administration/uploads/critic-scores/new',
        name: 'administration-uploads-new-critic-scores',
        component: () => import('@/views/administration/uploads/UploadCriticScores.vue'),
        meta: {
            resource: permissionSubjectName(PermissionEnum.VINTAGE_SCORE_UPLOAD_CREATE, PermissionSuffix.USER),
            action: permissionActionName(PermissionEnum.VINTAGE_SCORE_UPLOAD_CREATE),
            pageTitle: 'pageTitles.administrationUploads.criticScores',
            goBackRoute: 'administration-uploads-critic-scores',
            breadcrumb: [
                {
                    text: 'breadcrumbs.administrationUploads.criticScores.uploads',
                },
                {
                    text: 'breadcrumbs.administrationUploads.criticScores.criticScores',
                    active: true,
                },
            ],
        },
    },
];
