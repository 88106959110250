import { handleOrganisationRoutes } from '@/helpers/router';
import { permissionActionName, permissionSubjectName, PermissionSuffix } from '@/service/api/core/enums/permission';
import { PermissionEnum } from '@vini-wine/core-enums';
export default [
    {
        path: '/organisation/:orgUuid/customers',
        name: 'customers-list',
        component: () => import('@/views/organisation/customers/Index.vue'),
        meta: {
            resource: permissionSubjectName(PermissionEnum.CUSTOMER_VIEW, PermissionSuffix.USER_ORGANISATION),
            action: permissionActionName(PermissionEnum.CUSTOMER_VIEW),
            pageTitle: 'pageTitles.customers.list',
            breadcrumb: [
                {
                    text: 'breadcrumbs.customers.customersList.customers',
                },
                {
                    text: 'breadcrumbs.customers.customersList.list',
                    active: true,
                },
            ],
        },
        beforeEnter: (to, from, next) => {
            handleOrganisationRoutes(to);
            next();
        },
    },
    {
        path: '/organisation/:orgUuid/customers/create',
        name: 'customers-create',
        component: () => import('@/views/organisation/customers/Create.vue'),
        meta: {
            resource: permissionSubjectName(PermissionEnum.CUSTOMER__CREATE, PermissionSuffix.USER_ORGANISATION),
            action: permissionActionName(PermissionEnum.CUSTOMER__CREATE),
            pageTitle: 'pageTitles.customers.new',
            goBackRoute: 'customers-list',
            breadcrumb: [
                {
                    text: 'breadcrumbs.customers.customersCreate.customers',
                },
                {
                    text: 'breadcrumbs.customers.customersCreate.create',
                    active: true,
                },
            ],
        },
        beforeEnter: (to, from, next) => {
            handleOrganisationRoutes(to);
            next();
        },
    },
    {
        path: '/organisation/:orgUuid/customers/:customerUuid/edit',
        name: 'customers-edit',
        component: () => import('@/views/organisation/customers/Edit.vue'),
        meta: {
            resource: permissionSubjectName(PermissionEnum.CUSTOMER__EDIT, PermissionSuffix.USER_ORGANISATION),
            action: permissionActionName(PermissionEnum.CUSTOMER__EDIT),
            pageTitle: 'pageTitles.customers.edit',
            goBackRoute: 'customers-list',
            breadcrumb: [
                {
                    text: 'breadcrumbs.customers.customersEdit.customers',
                },
                {
                    text: 'breadcrumbs.customers.customersEdit.edit',
                    active: true,
                },
            ],
        },
        beforeEnter: (to, from, next) => {
            handleOrganisationRoutes(to);
            next();
        },
    },
    {
        path: '/organisation/:orgUuid/customers/:customerUuid',
        name: 'customers-details',
        component: () => import('@/views/organisation/customers/Show.vue'),
        meta: {
            resource: permissionSubjectName(PermissionEnum.CUSTOMER_VIEW, PermissionSuffix.USER_ORGANISATION),
            action: permissionActionName(PermissionEnum.CUSTOMER_VIEW),
            pageTitle: 'pageTitles.customers.details',
            goBackRoute: 'customers-list',
            breadcrumb: [
                {
                    text: 'breadcrumbs.customers.customersDetails.customers',
                },
                {
                    text: 'breadcrumbs.customers.customersDetails.details',
                    active: true,
                },
            ],
        },
        beforeEnter: (to, from, next) => {
            handleOrganisationRoutes(to);
            next();
        },
    },
];
