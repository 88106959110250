// eslint-disable-next-line import/no-cycle
import { bindAbilities, DEFAULT_ABILITIES } from '@/helpers/auth';
import { CookieEnum, DateTimeFormatEnum, TimezoneEnum, } from '@vini-wine/core-enums';
import { Vue } from 'vue-property-decorator';
import { permissionActionName, permissionComponentName, PermissionSuffix } from '@/service/api/core/enums/permission';
export const ADMIN_STATE_MUTATIONS = {
    USER_LOGGED_IN: 'USER_LOGGED_IN',
    USER_LOGGED_OUT: 'USER_LOGGED_OUT',
    ACCOUNT_UPDATED: 'ACCOUNT_UPDATED',
    ORGANISATION_CHANGED: 'ORGANISATION_CHANGED',
    CHANGE_NOTIFICATIONS_PRE_READ: 'CHANGE_NOTIFICATIONS_PRE_READ',
    ADD_ORGANISATION: 'ADD_ORGANISATION',
    PERMISSIONS_CHANGED: 'PERMISSIONS_CHANGED',
    ADD_CURRENCY_RATES: 'ADD_CURRENCY_RATES',
    UPDATE_CURRENCY_RATE: 'UPDATE_CURRENCY_RATE',
    UPDATE_ORGANISATION: 'UPDATE_ORGANISATION',
    UPDATE_ORGANISATION_DEFAULT_TIMEZONE: 'UPDATE_ORGANISATION_DEFAULT_TIMEZONE',
    UPDATE_USER_ORGANISATION: 'UPDATE_USER_ORGANISATION',
    UPDATE_USER_ACCOUNT: 'UPDATE_USER_ACCOUNT',
    UPDATE_ORGANISATION_PLAN: 'UPDATE_ORGANISATION_PLAN',
};
export const ADMIN_STATE_GETTERS = {
    USER: 'USER',
    USER_LOGGED_IN: 'USER_LOGGED_IN',
    CURRENT_ORGANISATION_UUID: 'CURRENT_ORGANISATION_UUID',
    CURRENT_ORGANISATION: 'CURRENT_ORGANISATION',
    CURRENT_ORGANISATION_PLAN: 'CURRENT_ORGANISATION_PLAN',
    CURRENT_ORGANISATION_USED_PLAN_META: 'CURRENT_ORGANISATION_USED_PLAN_META',
    ORGANISATIONS: 'ORGANISATIONS',
    CURRENCY_RATES: 'CURRENCY_RATES',
    CURRENCY_RATE: 'CURRENCY_RATE',
    NOTIFICATIONS_PRE_READ: 'NOTIFICATIONS_PRE_READ',
    DEFAULT_TIMEZONE: 'DEFAULT_TIMEZONE',
    DEFAULT_DATE_TIME_FORMAT: 'DEFAULT_DATE_TIME_FORMAT',
};
const INITIAL_USER_STATE = {
    user: {
        user: null,
        loggedIn: false,
        numNotificationsUnPreRead: 0,
        firstName: 'Vini',
        lastName: 'User',
        email: 'user@vini-wine.com',
        emailVerified: false,
        preferredLanguage: null,
        preferredCurrency: null,
        organisations: [],
        currentOrganisationUuid: null,
        abilities: [{
                subject: 'auth',
                action: 'read',
            }],
        avatarUrl: null,
        defaultDateTimeFormat: DateTimeFormatEnum.FORMAT_1,
        defaultTimezone: TimezoneEnum.UTC,
        currentOrganisationUsedPlanMeta: {
            usedPlan: {
                upload: 0, uploadService: 0, alert: 0, userOrganisation: 0,
            },
        },
    },
    currencyRates: [],
};
const initState = () => JSON.parse(JSON.stringify(INITIAL_USER_STATE));
const updateUserOrganisation = (state, userOrganisation) => {
    const el = state.user.organisations.find(a => a.organisation.uuid === userOrganisation.organisation.uuid);
    if (el) {
        const index = state.user.organisations.indexOf(el);
        if (index !== -1) {
            state.user.organisations[index] = userOrganisation;
        }
    }
};
const setPermissionsAndOrganisations = (state, account) => {
    // reset the abilities
    state.user.abilities = [{
            subject: 'auth',
            action: 'read',
        }];
    if (account.userOrganisations && account.userOrganisations.length > 0) {
        state.user.organisations = account.userOrganisations;
    }
    state.user.emailVerified = account.emailVerified;
    // Set User ability of login
    state.user.abilities.push(DEFAULT_ABILITIES.USER_LOGGED_IN);
    // Add permissions to the current user
    const mapPermissions = (permission, suffix = '') => {
        state.user.abilities.push({
            subject: `${suffix}${permissionComponentName(permission.id)}`,
            action: permissionActionName(permission.id),
        });
    };
    // User Permissions
    account.permissions?.forEach(permission => mapPermissions(permission));
    // User UserOrganisation Permissions
    if (account.selectedUserOrganisation) {
        // Set currentOrganisation to the default userOrganisation of the user
        state.user.currentOrganisationUuid = account.selectedUserOrganisation.organisation.uuid;
        // update the organisations array with the default user organisation
        updateUserOrganisation(state, account.selectedUserOrganisation);
        // Set user Organisation Permissions
        if (account.selectedUserOrganisation.permissions) {
            account.selectedUserOrganisation.permissions.forEach(permission => mapPermissions(permission, PermissionSuffix.USER_ORGANISATION));
        }
        // set organisation permissions
        if (account.selectedUserOrganisation.organisation.permissions) {
            account.selectedUserOrganisation.organisation.permissions.forEach(permission => mapPermissions(permission, PermissionSuffix.ORGANISATION));
        }
    }
    else if (account.userOrganisations && account.userOrganisations.length > 0) {
        // Set currentOrganisation to the first userOrganisation of the user
        state.user.currentOrganisationUuid = account.userOrganisations[0].organisation.uuid;
        // Set user Organisation Permissions
        if (account.userOrganisations[0].permissions) {
            account.userOrganisations[0].permissions.forEach(permission => mapPermissions(permission, PermissionSuffix.USER_ORGANISATION));
        }
        // set organisation permissions
        if (account.userOrganisations[0].organisation.permissions) {
            account.userOrganisations[0].organisation.permissions.forEach(permission => mapPermissions(permission, PermissionSuffix.ORGANISATION));
        }
    }
    // always set the current organisation id when the user logs in into the cookie
    Vue.$cookies.set(CookieEnum.SELECTED_ORGANISATION, state.user.currentOrganisationUuid);
    bindAbilities(state.user.abilities);
};
const updateUser = (state, account) => {
    state.user.uuid = account.uuid;
    state.user.firstName = account.person.firstName;
    state.user.lastName = account.person.lastName;
    state.user.preferredLanguage = account.person.preferredLanguage;
    state.user.preferredCurrency = account.person.preferredCurrency;
    state.user.email = account.email;
    state.user.emailVerified = account.emailVerified;
    if (account.loggedIn) {
        state.user.loggedIn = account.loggedIn;
    }
    if (state.user.numNotificationsUnPreRead !== undefined && account.numNotificationsUnPreRead !== undefined) {
        state.user.numNotificationsUnPreRead = account.numNotificationsUnPreRead;
    }
    state.user.avatarUrl = account.avatar ? account.avatar.url : undefined;
    state.user.defaultTimezone = account.defaultTimezone;
    state.user.defaultDateTimeFormat = account.defaultDateTimeFormat;
    setPermissionsAndOrganisations(state, account);
};
export class AdminStore {
    constructor() {
        this.namespaced = true;
        this.state = initState();
        this.getters = {
            [ADMIN_STATE_GETTERS.USER_LOGGED_IN]: (state) => state.user.loggedIn,
            [ADMIN_STATE_GETTERS.CURRENCY_RATES]: (state) => state.currencyRates,
            [ADMIN_STATE_GETTERS.DEFAULT_TIMEZONE]: (state) => state.user.defaultTimezone,
            [ADMIN_STATE_GETTERS.DEFAULT_DATE_TIME_FORMAT]: (state) => state.user.defaultDateTimeFormat,
            [ADMIN_STATE_GETTERS.USER]: (state) => state.user,
            [ADMIN_STATE_GETTERS.CURRENT_ORGANISATION]: (state) => state.user.organisations.find(organisation => organisation.organisation.uuid === state.user.currentOrganisationUuid),
            [ADMIN_STATE_GETTERS.CURRENT_ORGANISATION_UUID]: (state) => state.user.currentOrganisationUuid,
            [ADMIN_STATE_GETTERS.ORGANISATIONS]: (state) => state.user.organisations,
            [ADMIN_STATE_GETTERS.NOTIFICATIONS_PRE_READ]: (state) => state.user.numNotificationsUnPreRead,
            [ADMIN_STATE_GETTERS.CURRENT_ORGANISATION_USED_PLAN_META]: (state) => state.user.currentOrganisationUsedPlanMeta,
            [ADMIN_STATE_GETTERS.CURRENT_ORGANISATION_PLAN]: (state) => state.user.organisations.find(organisation => organisation.organisation.uuid === state.user.currentOrganisationUuid)?.organisation.plan,
        };
        this.mutations = {
            [ADMIN_STATE_MUTATIONS.UPDATE_ORGANISATION_PLAN](state, plan) {
                // Clone the plan object to avoid modifying the original plan
                const planWithoutMeta = { ...plan };
                // Remove the 'meta' property from the cloned plan
                // @ts-ignore
                delete planWithoutMeta.meta;
                // workaround to make the organisations reactive (change)
                const org = state.user.organisations.find((a) => a.organisation.uuid === state.user.currentOrganisationUuid);
                if (org) {
                    // eslint-disable-next-line no-underscore-dangle
                    const _org = { ...org, organisation: { ...org.organisation, plan: planWithoutMeta } };
                    state.user.organisations.splice(state.user.organisations.indexOf(org), 1);
                    state.user.organisations.push(_org);
                    // @ts-ignore
                    state.user.currentOrganisationUsedPlanMeta = plan.meta;
                }
            },
            [ADMIN_STATE_MUTATIONS.UPDATE_ORGANISATION](state, organisation) {
                const el = state.user.organisations.find(a => a.organisation.uuid === organisation.uuid);
                if (el) {
                    const index = state.user.organisations.indexOf(el);
                    if (index !== -1) {
                        state.user.organisations[index].organisation = organisation;
                    }
                }
            },
            [ADMIN_STATE_MUTATIONS.UPDATE_USER_ORGANISATION](state, userOrganisation) {
                updateUserOrganisation(state, userOrganisation);
            },
            [ADMIN_STATE_MUTATIONS.USER_LOGGED_IN](state, account) {
                // clear the state for the user
                state.user = { ...initState().user };
                updateUser(state, account);
            },
            [ADMIN_STATE_MUTATIONS.UPDATE_USER_ACCOUNT](state, account) {
                updateUser(state, account);
            },
            [ADMIN_STATE_MUTATIONS.ADD_CURRENCY_RATES](state, rates) {
                // @ts-ignore
                state.currencyRates = Object.keys(rates).map(key => rates[key]);
            },
            [ADMIN_STATE_MUTATIONS.UPDATE_CURRENCY_RATE](state, rate) {
                const index = state.currencyRates.indexOf(state.currencyRates.find(a => a.currencyCode === rate.currencyCode));
                state.currencyRates.splice(index, 1);
                state.currencyRates.push(rate);
            },
            [ADMIN_STATE_MUTATIONS.PERMISSIONS_CHANGED](state, account) {
                setPermissionsAndOrganisations(state, account);
            },
            [ADMIN_STATE_MUTATIONS.USER_LOGGED_OUT](state) {
                state.user = { ...initState().user };
            },
            [ADMIN_STATE_MUTATIONS.ACCOUNT_UPDATED](state, data) {
                Vue.set(state.user, 'firstName', data.firstName);
                Vue.set(state.user, 'lastName', data.lastName);
                Vue.set(state.user, 'email', data.email);
                Vue.set(state.user, 'preferredLanguage', data.preferredLanguage);
                Vue.set(state.user, 'preferredCurrency', data.preferredCurrency);
                Vue.set(state.user, 'defaultDateTimeFormat', data.defaultDateTimeFormat);
                Vue.set(state.user, 'defaultTimezone', data.defaultTimezone);
            },
            [ADMIN_STATE_MUTATIONS.ORGANISATION_CHANGED](state, organisationId) {
                state.user.currentOrganisationUuid = organisationId;
                // Add permissions to the current user
                const mapPermissions = (permission, suffix = '') => {
                    state.user.abilities.push({
                        subject: `${suffix}${permissionComponentName(permission.id)}`,
                        action: permissionActionName(permission.id),
                    });
                };
                // Retrieve all non user organisation abilities
                state.user.abilities = state.user.abilities.filter(userAbility => !userAbility.subject.startsWith(PermissionSuffix.USER_ORGANISATION));
                // Retrieve all non organisation abilities
                state.user.abilities = state.user.abilities.filter(userAbility => !userAbility.subject.startsWith(PermissionSuffix.ORGANISATION));
                // retrieve the organisation from the selected organisation id
                const currentOrganisation = state.user.organisations.find(userOrganisation => userOrganisation.organisation.uuid === organisationId);
                // If there was a organisation found then set all user organisation permission that the user organisation has.
                if (typeof currentOrganisation !== 'undefined' && currentOrganisation.permissions) {
                    currentOrganisation.permissions.forEach(permission => mapPermissions(permission, PermissionSuffix.USER_ORGANISATION));
                    if (currentOrganisation.organisation && currentOrganisation.organisation.permissions) {
                        currentOrganisation.organisation.permissions.forEach(permission => mapPermissions(permission, PermissionSuffix.ORGANISATION));
                    }
                }
            },
            [ADMIN_STATE_MUTATIONS.CHANGE_NOTIFICATIONS_PRE_READ](state, numNotificationsUnPreRead) {
                state.user.numNotificationsUnPreRead = numNotificationsUnPreRead;
            },
            [ADMIN_STATE_MUTATIONS.ADD_ORGANISATION](state, organisation) {
                state.user.organisations.unshift(organisation);
            },
            [ADMIN_STATE_MUTATIONS.UPDATE_ORGANISATION_DEFAULT_TIMEZONE](state, organisation) {
                const el = state.user.organisations.find(a => a.organisation.uuid === organisation.uuid);
                if (el) {
                    const index = state.user.organisations.indexOf(el);
                    if (index !== 1) {
                        state.user.organisations[index].organisation.defaultTimezone = organisation.defaultTimezone;
                    }
                }
            },
        };
        this.actions = {};
    }
}
AdminStore.NS = 'admin';
