import { permissionActionName, permissionSubjectName, PermissionSuffix } from '@/service/api/core/enums/permission';
import { PermissionEnum } from '@vini-wine/core-enums';
export default [
    {
        path: '/administration/users',
        name: 'users-list',
        component: () => import('@/views/administration/users/UsersList.vue'),
        meta: {
            resource: permissionSubjectName(PermissionEnum.USER__VIEW, PermissionSuffix.USER),
            action: permissionActionName(PermissionEnum.USER__VIEW),
            pageTitle: 'pageTitles.users.list',
            breadcrumb: [
                {
                    text: 'breadcrumbs.users.usersList.users',
                },
                {
                    text: 'breadcrumbs.users.usersList.list',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/administration/users/:uuid/details',
        name: 'user-details',
        component: () => import('@/views/administration/users/UserDetails.vue'),
        meta: {
            resource: permissionSubjectName(PermissionEnum.USER__DETAILS, PermissionSuffix.USER),
            action: permissionActionName(PermissionEnum.USER__DETAILS),
            pageTitle: 'pageTitles.users.details',
            goBackRoute: 'users-list',
            breadcrumb: [
                {
                    text: 'breadcrumbs.users.userDetails.users',
                },
                {
                    text: 'breadcrumbs.users.userDetails.details',
                    active: true,
                },
            ],
        },
    },
];
