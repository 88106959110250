import { handleOrganisationRoutes } from '@/helpers/router';
import { permissionActionName, permissionSubjectName, PermissionSuffix } from '@/service/api/core/enums/permission';
import { PermissionEnum } from '@vini-wine/core-enums';
export default [
    {
        path: '/organisation/:orgUuid/uploads',
        name: 'uploads-list',
        component: () => import('@/views/organisation/uploads/UploadsList.vue'),
        meta: {
            resource: permissionSubjectName(PermissionEnum.UPLOAD__VIEW, PermissionSuffix.USER_ORGANISATION),
            action: permissionActionName(PermissionEnum.UPLOAD__VIEW),
            pageTitle: 'pageTitles.uploads.list',
            breadcrumb: [
                {
                    text: 'breadcrumbs.uploads.list.uploads',
                },
                {
                    text: 'breadcrumbs.uploads.list.list',
                    active: true,
                },
            ],
        },
        beforeEnter: (to, from, next) => {
            handleOrganisationRoutes(to);
            next();
        },
    },
    {
        path: '/organisation/:orgUuid/uploads/new',
        name: 'uploads-new',
        component: () => import('@/views/organisation/uploads/UploadNew.vue'),
        meta: {
            resource: permissionSubjectName(PermissionEnum.UPLOAD__CREATE, PermissionSuffix.USER_ORGANISATION),
            action: permissionActionName(PermissionEnum.UPLOAD__CREATE),
            pageTitle: 'pageTitles.uploads.new',
            goBackRoute: 'uploads-list',
            breadcrumb: [
                {
                    text: 'breadcrumbs.uploads.new.uploads',
                },
                {
                    text: 'breadcrumbs.uploads.new.new',
                    active: true,
                },
            ],
        },
        beforeEnter: (to, from, next) => {
            handleOrganisationRoutes(to);
            next();
        },
    },
    {
        path: '/organisation/:orgUuid/uploads/:uploadUuid',
        name: 'uploads-show',
        component: () => import('@/views/organisation/uploads/UploadLandingPage.vue'),
        meta: {
            resource: permissionSubjectName(PermissionEnum.UPLOAD__VIEW, PermissionSuffix.USER_ORGANISATION),
            action: permissionActionName(PermissionEnum.UPLOAD__VIEW),
            pageTitle: 'pageTitles.uploads.details',
            goBackRoute: 'uploads-list',
            breadcrumb: [
                {
                    text: 'breadcrumbs.uploads.details.uploads',
                },
                {
                    text: 'breadcrumbs.uploads.details.details',
                    active: true,
                },
            ],
        },
        beforeEnter: (to, from, next) => {
            handleOrganisationRoutes(to);
            next();
        },
    },
];
