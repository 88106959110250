// eslint-disable-next-line import/no-cycle
import { handleOrganisationRoutes } from '@/helpers/router';
import { permissionActionName, permissionSubjectName, PermissionSuffix } from '@/service/api/core/enums/permission';
import { PermissionEnum } from '@vini-wine/core-enums';
export default [
    {
        path: '/organisation/:orgUuid/users',
        name: 'organisation-users-list',
        component: () => import('@/views/organisation/users/Index.vue'),
        meta: {
            resource: permissionSubjectName(PermissionEnum.USER__VIEW, PermissionSuffix.USER_ORGANISATION),
            action: permissionActionName(PermissionEnum.USER__VIEW),
            pageTitle: 'pageTitles.organisationUsers.list',
            breadcrumb: [
                {
                    text: 'breadcrumbs.organisationUsers.organisationUsersList.organisationUsers',
                },
                {
                    text: 'breadcrumbs.organisationUsers.organisationUsersList.list',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/organisation/:orgUuid/users/:userUuid',
        name: 'organisation-user-details',
        component: () => import('@/views/organisation/users/Show.vue'),
        meta: {
            resource: permissionSubjectName(PermissionEnum.USER__DETAILS, PermissionSuffix.USER_ORGANISATION),
            action: permissionActionName(PermissionEnum.USER__DETAILS),
            pageTitle: 'pageTitles.organisationUsers.details',
            goBackRoute: 'organisation-users-list',
            breadcrumb: [
                {
                    text: 'breadcrumbs.organisationUsers.organisationUserDetails.organisationUsers',
                },
                {
                    text: 'breadcrumbs.organisationUsers.organisationUserDetails.details',
                    active: true,
                },
            ],
        },
        beforeEnter: (to, from, next) => {
            handleOrganisationRoutes(to);
            next();
        },
    },
    {
        path: '/organisation/:orgUuid/user-invitations',
        name: 'organisation-user-invitations',
        component: () => import('@/views/organisation/user-invitations/Index.vue'),
        meta: {
            resource: permissionSubjectName(PermissionEnum.INVITE_USER__VIEW, PermissionSuffix.USER_ORGANISATION),
            action: permissionActionName(PermissionEnum.INVITE_USER__VIEW),
            pageTitle: 'pageTitles.organisationUsers.invitations',
            breadcrumb: [
                {
                    text: 'breadcrumbs.organisationUsers.organisationUserInvitations.organisationUsers',
                },
                {
                    text: 'breadcrumbs.organisationUsers.organisationUserInvitations.invitations',
                    active: true,
                },
            ],
        },
        beforeEnter: (to, from, next) => {
            handleOrganisationRoutes(to);
            next();
        },
    },
];
