import { DEFAULT_ABILITIES } from '@/helpers/auth';
export default [
    {
        path: '/administration/vintage-products/:vintageProductUuid',
        name: 'administration-vintage-products-show',
        component: () => import('@/views/administration/vintage-products/Show.vue'),
        meta: {
            resource: DEFAULT_ABILITIES.USER_LOGGED_IN.subject,
            action: DEFAULT_ABILITIES.USER_LOGGED_IN.action,
            pageTitle: 'pageTitles.vintageProducts.show',
            breadcrumb: [
                {
                    text: 'breadcrumbs.vintageProducts.show.vintageProducts',
                },
                {
                    text: 'breadcrumbs.vintageProducts.show.details',
                    active: true,
                },
            ],
        },
    },
];
