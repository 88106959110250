import { permissionActionName, permissionSubjectName, PermissionSuffix } from '@/service/api/core/enums/permission';
import { PermissionEnum } from '@vini-wine/core-enums';
export default [
    {
        path: '/administration/organisations',
        name: 'organisations-list',
        component: () => import('@/views/administration/organisation/Index.vue'),
        meta: {
            resource: permissionSubjectName(PermissionEnum.ORGANISATION__VIEW, PermissionSuffix.USER),
            action: permissionActionName(PermissionEnum.ORGANISATION__VIEW),
            pageTitle: 'pageTitles.organisations.list',
            breadcrumb: [
                {
                    text: 'breadcrumbs.organisation.organisationList.organisations',
                },
                {
                    text: 'breadcrumbs.organisation.organisationList.list',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/administration/organisations/:orgUuid',
        name: 'organisation-details',
        component: () => import('@/views/administration/organisation/Show.vue'),
        meta: {
            resource: permissionSubjectName(PermissionEnum.ORGANISATION__DETAILS, PermissionSuffix.USER),
            action: permissionActionName(PermissionEnum.ORGANISATION__DETAILS),
            pageTitle: 'pageTitles.organisations.details',
            goBackRoute: 'organisations-list',
            breadcrumb: [
                {
                    text: 'breadcrumbs.organisation.organisationDetails.organisations',
                },
                {
                    text: 'breadcrumbs.organisation.organisationDetails.details',
                    active: true,
                },
            ],
        },
    },
];
