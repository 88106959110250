import { handleOrganisationRoutes } from '@/helpers/router';
import { permissionActionName, permissionSubjectName, PermissionSuffix } from '@/service/api/core/enums/permission';
import { PermissionEnum } from '@vini-wine/core-enums';
export default [
    {
        path: '/organisation/:orgUuid/suppliers',
        name: 'suppliers-list',
        component: () => import('@/views/organisation/suppliers/Index.vue'),
        meta: {
            resource: permissionSubjectName(PermissionEnum.SUPPLIER__VIEW, PermissionSuffix.USER_ORGANISATION),
            action: permissionActionName(PermissionEnum.SUPPLIER__VIEW),
            pageTitle: 'pageTitles.suppliers.list',
            breadcrumb: [
                {
                    text: 'breadcrumbs.suppliers.suppliersList.suppliers',
                },
                {
                    text: 'breadcrumbs.suppliers.suppliersList.list',
                    active: true,
                },
            ],
        },
        beforeEnter: (to, from, next) => {
            handleOrganisationRoutes(to);
            next();
        },
    },
    {
        path: '/organisation/:orgUuid/suppliers/create',
        name: 'suppliers-create',
        component: () => import('@/views/organisation/suppliers/Create.vue'),
        meta: {
            resource: permissionSubjectName(PermissionEnum.SUPPLIER__CREATE, PermissionSuffix.USER_ORGANISATION),
            action: permissionActionName(PermissionEnum.SUPPLIER__CREATE),
            pageTitle: 'pageTitles.suppliers.new',
            goBackRoute: 'suppliers-list',
            breadcrumb: [
                {
                    text: 'breadcrumbs.suppliers.suppliersCreate.suppliers',
                },
                {
                    text: 'breadcrumbs.suppliers.suppliersCreate.create',
                    active: true,
                },
            ],
        },
        beforeEnter: (to, from, next) => {
            handleOrganisationRoutes(to);
            next();
        },
    },
    {
        path: '/organisation/:orgUuid/suppliers/:supplierUuid/edit',
        name: 'suppliers-edit',
        component: () => import('@/views/organisation/suppliers/Edit.vue'),
        meta: {
            resource: permissionSubjectName(PermissionEnum.SUPPLIER__EDIT, PermissionSuffix.USER_ORGANISATION),
            action: permissionActionName(PermissionEnum.SUPPLIER__EDIT),
            pageTitle: 'pageTitles.suppliers.edit',
            goBackRoute: 'suppliers-list',
            breadcrumb: [
                {
                    text: 'breadcrumbs.suppliers.suppliersEdit.suppliers',
                },
                {
                    text: 'breadcrumbs.suppliers.suppliersEdit.edit',
                    active: true,
                },
            ],
        },
        beforeEnter: (to, from, next) => {
            handleOrganisationRoutes(to);
            next();
        },
    },
    {
        path: '/organisation/:orgUuid/suppliers/:supplierUuid',
        name: 'suppliers-details',
        component: () => import('@/views/organisation/suppliers/Show.vue'),
        meta: {
            resource: permissionSubjectName(PermissionEnum.SUPPLIER__VIEW, PermissionSuffix.USER_ORGANISATION),
            action: permissionActionName(PermissionEnum.SUPPLIER__VIEW),
            pageTitle: 'pageTitles.suppliers.details',
            goBackRoute: 'suppliers-list',
            breadcrumb: [
                {
                    text: 'breadcrumbs.suppliers.suppliersDetails.suppliers',
                },
                {
                    text: 'breadcrumbs.suppliers.suppliersDetails.details',
                    active: true,
                },
            ],
        },
        beforeEnter: (to, from, next) => {
            handleOrganisationRoutes(to);
            next();
        },
    },
];
