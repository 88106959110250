import { handleOrganisationRoutes } from '@/helpers/router';
import { permissionActionName, permissionSubjectName, PermissionSuffix } from '@/service/api/core/enums/permission';
import { PermissionEnum } from '@vini-wine/core-enums';
export default [
    {
        path: '/organisation/:orgUuid/orders',
        name: 'organisation-orders-list',
        component: () => import('@/views/organisation/orders/Index.vue'),
        meta: {
            resource: permissionSubjectName(PermissionEnum.ORDER__VIEW, PermissionSuffix.USER_ORGANISATION),
            action: permissionActionName(PermissionEnum.ORDER__VIEW),
            pageTitle: 'pageTitles.orders.list',
            breadcrumb: [
                {
                    text: 'breadcrumbs.orders.ordersList.orders',
                },
                {
                    text: 'breadcrumbs.orders.ordersList.list',
                    active: true,
                },
            ],
        },
        beforeEnter: (to, from, next) => {
            handleOrganisationRoutes(to);
            next();
        },
    },
];
